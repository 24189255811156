import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";

// Components
import Header from "./components/header";
import Banner from "./components/banner";
import ControlPanel from "./components/controlPanel";

// Assets
import ImgLogo from "./assets/images/logo.png";
import ImagfinancialReporting from "./assets/images/financial-reporting.jpg";
import ImgFinancial from "./assets/images/financial-statement.jpg";

// future import it to dynamic
// why import P2564 because it can import 2564 it error
import P2567 from "./assets/pdfs/2567.pdf";
import P25662567 from "./assets/pdfs/2566-2567.pdf";
import P2564 from "./assets/pdfs/2564.pdf";
import PTT_GREEN_ENERGY from "./assets/pdfs/PTT_GREEN_ENERGY.pdf";
import PTT_GREEN_ENERGY_Average_price from "./assets/pdfs/PTT_GREEN_ENERGY_Average_price.pdf";

import PTTGE_SG_280_10_06_67 from "./assets/pdfs/280-PTTGE_SG_ประกาศผู้ชนะการเสนอราคางานจ้างผู้สอบบัญชี_10มิย67.pdf"; //280-PTTGE_SG_ประกาศผู้ชนะการเสนอราคางานจ้างผู้สอบบัญชี_10มิย67
import Additional_Accounting_Service from "./assets/pdfs/ประกาศผู้ชนะงานจ้างบริการ Additional Accounting Service.pdf";
import Company_document_scanning_service from "./assets/pdfs/ประกาศผู้ชนะงานจ้างบริการสแกนเอกสารของบริษัทฯ.pdf";
import Additional_Accounting_Service_price from "./assets/pdfs/4.1 ประกาศราคากลางจ้างบริการ Additional Accounting Service.pdf";
import Company_document_scanning_service_price from "./assets/pdfs/ประกาศราคากลางจ้างบริการแสกนเอกสารของบริษัทฯ.pdf";
import scanning_service_2567 from "./assets/pdfs/ประกาศแผนงานจ้างบริการสแกนเอกสารบริษัทฯ ประจำปี 2567.pdf";
import support_the_work from "./assets/pdfs/ประกาศผู้ชนะการเสนอราคา จ้างเหมาบริการเพื่อสนับสนุนงาน.pdf";
import PTTGE_TH_01_2567 from "./assets/pdfs/ประกาศ PTTGE TH 01-2567 เรื่อง จำหน่ายทรัพย์สิน.pdf";
import Financial_statements_2566 from "./assets/pdfs/งบการเงิน ประจำปี 2566.pdf";

function App() {
  const storePdf = [
    {
      pdf: PTTGE_SG_280_10_06_67,
      name: "ประกาศผู้ชนะการเสนอราคางานจ้างผู้สอบบัญชีประจำปี 2566-2567",
    },
    {
      pdf: Additional_Accounting_Service,
      name: "ประกาศผู้ชนะงานจ้างบริการ Additional Accounting Service",
    },
    {
      pdf: Company_document_scanning_service,
      name: "ประกาศผู้ชนะงานจ้างบริการสแกนเอกสารของบริษัทฯ",
    },
    {
      pdf: Additional_Accounting_Service_price,
      name: "ประกาศราคากลางจ้างบริการ Additional Accounting Service",
    },
    {
      pdf: Company_document_scanning_service_price,
      name: "ประกาศราคากลางจ้างบริการสแกนเอกสารของบริษัทฯ",
    },
    {
      pdf: scanning_service_2567,
      name: "ประกาศแผนงานจ้างเหมาบริการสแกนเอกสาร ประจำปี 2567",
    },
    {
      pdf: support_the_work,
      name: "ประกาศผู้ชนะการเสนอราคาจ้างเหมาบริการเพื่อสนับสนุนงานของบริษัทฯ ประจำปี 2567",
    },

    { pdf: P2567, name: "แผนการจัดซื้อจัดจ้าง ประจำปีงบประมาณ 2567" },
    { pdf: P2564, name: "งบการเงิน ประจำปี 2564" },
    {
      pdf: P25662567,
      name: "ประกาศราคากลางสำหรับงานจัดจ้างผู้สอบบัญชี ประจำปี 2566-2567",
    },
    {
      pdf: PTT_GREEN_ENERGY,
      name: "จ้างเหมาบริการเพื่อสนับสนุนงานของบริษัท ปตท กรีน เอ็นเนอร์ยี่ จำกัด",
    },
    {
      pdf: PTT_GREEN_ENERGY_Average_price,
      name: "ประกาศราคากลางสำหรับงานจ้างเหมาบริการเพื่อสนับสนุนงานของบริษัท ปตท กรีน เอ็นเนอร์ยี่ จำกัด",
    },
    {
      pdf: PTTGE_TH_01_2567,
      name: "ประกาศจำหน่ายทรัพย์สินของ บริษัท ปตท กรีน เอ็นเนอร์ยี่ (ประเทศไทย) จำกัด",
    },
    {
      pdf: Financial_statements_2566,
      name: "งบการเงิน ประจำปี 2566",
    },
  ];

  const TextSection = styled.div`
    font-size: 1.8rem;
    color: #000;
  `;

  return (
    <>
      <Box height={"100dvh"}>
        <Header
          image={ImgLogo}
          companyName="PTT Green Energy Pte. Ltd. "
        ></Header>
        <Banner image={ImagfinancialReporting} />
        <Container>
          <Box display={"flex"} justifyContent={"center"} padding={"24px"}>
            <TextSection>เอกสารเผยแพร่</TextSection>
          </Box>
          <ControlPanel image={ImgFinancial} storePdf={storePdf} />
        </Container>
      </Box>
    </>
  );
}

export default App;
