
import React from 'react';
import styled from '@emotion/styled'
import {  Grid } from "@mui/material";
import imageDownload from '../assets/images/download.svg'
import DownloadButton from './downloadButton'

      

type ControlPanelProps = {
  image: string;
  storePdf?: Array<any>;
};


const ControlPanelContainer = styled.div`
  margin: auto;

`;





const ControlPanel: React.FC<ControlPanelProps> = ({ image = "", storePdf = [] }) => {
  return (
    <ControlPanelContainer> 
      <Grid container spacing={2}>
        
        {storePdf.map((item, index) => {
          return (
            <Grid item xs={12} md={12} lg ={6} key={`pdfSelected-${item.name}`}>
              <DownloadButton  id={`pdfSelected-${index}`} name={item.name} href={item.pdf} imageDownload={imageDownload} />
            </Grid>
          )
        })}

      </Grid>
    </ControlPanelContainer>
  );
};




export default ControlPanel;
